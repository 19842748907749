import * as React from 'react';
import { Button, ButtonProps } from "@material-ui/core"
import styled from 'styled-components';
import { theme } from '../theme';

export interface A8ButtonProps extends Omit<ButtonProps, 'variant'>, A8ButtonExtension {

}

type ExtendedVariants = 'contained' | 'outlined' | 'text' | 'text-white' | 'outlined-white' | 'contained-white';

interface A8ButtonExtension {
  wide?: boolean;
  variant?: ExtendedVariants;
}

export const A8ButtonFilter = React.forwardRef((props: A8ButtonProps, ref: any) => {
  const { wide, variant, ...rest } = props;
  const baseVariant: any = variant != null ? variant.split('-')[0] : undefined;
  return (<Button ref={ref} {...rest} variant={baseVariant} />);
});

export const A8Button = styled(A8ButtonFilter) <A8ButtonProps>`
  text-transform: none;
  font-family: ${() => theme.fontFamily};
  font-weight: 500;
  min-width: ${p => p.wide === true ? '7rem' : '2.6rem'};
  
  ${(p) => {
    switch (p.variant) {
      case 'contained':
      case 'contained-white': {
        const color = p.variant === 'contained' ? 'white' : theme.primaryColor;
        const background = p.variant === 'contained' ? theme.primaryColor : 'white';
        const disabledColor = p.variant === 'contained' ? '#ffffff80' : '#00000080';
        return `
        background-color: ${background};
        color: ${color};
        &:hover, &:active, &:focus{
          background-color: ${background}!important;
          color: ${color};
        }
        &:disabled, &.Mui-disabled{
          color: ${disabledColor}!important;
        }
      `};
      case 'text': return `
        color: ${theme.primaryColor};
        &:hover, &:active, &:focus{
          background-color: ${theme.primaryColor}16!important;
        }
      `;
      case 'text-white': return `
        color: #fff;
        &:hover, &:active, &:focus{
          background-color: #ffffff16!important;
        }
      `;
      case 'outlined':
      case 'outlined-white': {
        const color = p.variant === 'outlined' ? theme.primaryColor : '#ffffff';
        return `
        background-color: transparent;
        color: ${color};
        border-color: ${color};
        text-transform: none;
        &:hover, &:active, &:focus{
          background-color: ${color}16!important;
          border-color: ${color};
        }
      `;
      };
      default: return '';
    }
  }}
`;