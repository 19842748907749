import * as React from 'react';
import { ButtonLine } from './ButtonLine';
import { A8Button } from './Button';
import styled from 'styled-components';
import { theme } from '../theme';

export interface ButtonRangeProps {
  options: Array<{ label: string, value: string, order?: number }>;
  initialValue?: OrderedValue[] | string[];
  onChange?: (value?: string[]) => void;
  subsetMode?: boolean; //when you current options set is just a slice of a bigger set - this way your initialValue items can be out of range of your current options set
  value?: OrderedValue[] | string[];
}

export interface OrderedValue {
  value: string;
  order?: number;
}


export const SelectEffectFilter = React.forwardRef(({ selection, ...rest }: React.InputHTMLAttributes<HTMLInputElement> & { selection: 'none' | 'first' | 'middle' | 'last' | 'first-last' }, ref: any) => {
  return (<div ref={ref} {...rest} />);
});

export const SelectEffect = styled(SelectEffectFilter)`
  background: ${theme.primaryColor}44;
  padding: 0.25rem;
  transition: all ease-in-out 0.1s;
  ${(p) => {
    switch (p.selection) {
      case "first": return `border-radius: 0.25rem 0rem 0rem 0.25rem;`;
      case "middle": return `border-radius: 0;`;
      case "last": return `border-radius: 0rem 0.25rem 0.25rem 0rem;`;
      case "first-last": return `border-radius: 0.25rem;`
      case "none": return `background: transparent;`;
    }
  }}
`;

export const ToggleButton = styled(A8Button)`
  text-transform: none;
  font-family: ${theme.fontFamily};
  font-weight: 500;
  min-width: 2.6rem;
  border: solid 1px ${theme.primaryColor};
  ${p => p.variant === 'text' ? 'border-color: transparent;' : ''}
  padding: 5px 15px;
`;

export const ButtonRange = ({
  initialValue,
  options,
  onChange,
  subsetMode = false,
  value
}: ButtonRangeProps) => {

  React.useEffect(() => {
    if(value != null) {
      if (value?.length && typeof value?.[0] === 'string') {
        const optionsValues = options.map(x => x.value);
        setRange((value as any[]).map((value: string) => ({ value, order: optionsValues.indexOf(value) })))
      } else {
        setRange(value as any)
      }
    }
  }, [value]);

  let initialValueNormalized: OrderedValue[] = [];
  if (initialValue?.length) {
    if (typeof initialValue?.[0] === 'string') {
      initialValueNormalized = (initialValue as any[]).map((value: string) => ({ value }));
    }
    else {
      initialValueNormalized = initialValue as any;
    }
  }

  //uses
  const [range, setRange] = React.useState<OrderedValue[]>(initialValueNormalized);
  const focusIndex = React.useRef<number | null>(null);
  const buttonLineRef = React.useRef<HTMLDivElement>(null);

  const noSelection = range == null || range.length === 0;
  const singleSelection = range != null && range[0]?.value != undefined && range[0]?.value === range[1]?.value;
  const regularSelection = range != null && range[0]?.value !== range[1]?.value;

  //keyboard navigation
  const handleKeyUp = (e: React.KeyboardEvent<any>) => {
    const move = (mod: number) => {
      e.preventDefault();
      e.stopPropagation();
      const targetIndex = Math.min(Math.max((focusIndex.current != null ? focusIndex.current : -1) + mod, 0), (options.length - 1));
      const div = buttonLineRef.current;
      if (div != null) {
        const element = div.querySelectorAll('button, input')[targetIndex];
        if (element) (element as any).focus();
      }
    }
    const target: any = e.target;
    if (target?.tagName === 'INPUT') { return; }
    if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
      move(-1);
    }
    else if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
      move(+1);
    }
  };

  let handleItemClick: (e: React.MouseEvent<any>) => void;
  //console.log('Define click handler');
  if (noSelection || regularSelection) {
    //initial selection
    handleItemClick = (e) => {
      const index = e.currentTarget?.dataset.index;
      const { order, value } = options[index];
      const first = { value, order: order == null ? parseInt(index) : order };
      const val = [first, first];
      setRange(val);
      onChange?.(val?.map(x => x.value));
    }
  }
  else {
    if (singleSelection) {
      handleItemClick = (e) => {
        const index = e.currentTarget?.dataset.index;
        const { value, order } = options[index];
        const second = { value, order: order == null ? parseInt(index) : order };
        const first = range[0];
        if (second.value === first.value) return;
        const val = [first, second];
        val.sort((a, b) => (a.order as any) > (b.order as any) ? 1 : -1);
        setRange(val);
        onChange?.(val?.map(x => x.value));
      }
    }
  }

  let selectionStart: number | null;
  let selectionEnd: number | null;
  // select start and end locally
  if (range.length) {
    if (subsetMode) {
      throw new Error('Not implemented.');
    }
    else { // select start and end using order - can be used for paginated ButtonRange, like calendars
      //console.log('Find selection start/end');
      const rangeV = range?.map(x => x.value);
      if(!rangeV) {
        selectionStart = null;
        selectionEnd = null;
      } else {
        options.forEach((x, i) => {
          if (rangeV.indexOf(x.value) > -1) {
            if (selectionStart == null) {
              selectionStart = i;
              if (rangeV[0] === rangeV[1]) {
                selectionEnd = i;
              }
            }
            else {
              selectionEnd = i;
            }
          }
        });
      }
    }
  }
  
  return (<ButtonLine spacingX={"0px"} spacingY={"1px"} ref={buttonLineRef} onKeyUp={handleKeyUp}>
    {options.map((x, i) => {
      let selected = false;
      let selection: 'none' | 'first' | 'middle' | 'last' | 'first-last' = 'none';
      if (selectionStart != null) {
        //complete selection
        if (i === selectionStart) {
          selected = true;
          if (i === selectionEnd) {
            selection = 'first-last';
          }
          else
            selection = 'first';
        } else if (i === selectionEnd) {
          selected = true;
          selection = 'last';
        }
        else if (selectionEnd && i >= selectionStart && i <= selectionEnd) {
          selection = 'middle';
        }
      }
      return (
        <SelectEffect selection={selection} key={x.value}>
          <ToggleButton
            data-index={i}
            data-meta-id={x.value}
            onFocus={() => { focusIndex.current = i; }}
            variant={selected ? "contained" : selection === 'middle' ? 'text' : 'outlined'}
            tabIndex={i > 0 ? -1 : undefined}
            onClick={handleItemClick}>
            {x.label}
          </ToggleButton>
        </SelectEffect>
      );
    })}
  </ButtonLine>);
}