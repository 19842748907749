import * as React from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'
import styled from 'styled-components';
import { theme } from '../theme';

export type InputProps = TextFieldProps;

export const TextFieldContainer = styled(TextField)`
  fieldset {
    border: solid 1px ${theme.primaryColor};
  }
  & label.Mui-focused {
    color: ${theme.primaryColor};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: solid 1px ${theme.primaryColor};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const Input = React.forwardRef(
  (props: InputProps, ref: any) => {
    return (
      <TextFieldContainer 
        ref={ref} 
        variant="outlined"
        margin="dense"
        size="small"
        {...props}
      />
    );
  }
)