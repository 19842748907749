import * as React from 'react';

interface LogoProps {
  height?: number;
  width?: number;
}

export const LogoWhite:React.FC<LogoProps> = ({ height = 15, width = 100 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 120 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M97.4529 7.27589L97.6791 7.35124C98.5412 7.63876 100 8.70281 100 10.8673C100 13.3778 98.0738 15 95.0927 15H94.5483C91.6147 15 89.7191 13.3778 89.7191 10.8673C89.7191 8.82141 90.969 7.69471 92.0393 7.35153L92.2729 7.27657L92.0411 7.19453C91.5558 7.02251 89.9718 6.28301 89.9718 3.98791C89.9718 1.52806 91.7404 0 94.5872 0H95.1122C97.9714 0 99.7476 1.52806 99.7476 3.98791C99.7476 6.26817 98.1633 7.01882 97.6778 7.19463L97.4529 7.27589ZM94.6262 12.9118H95.0927C96.554 12.9118 97.5358 11.9988 97.5358 10.64C97.5358 9.2475 96.577 8.3478 95.0927 8.3478H94.6262C93.1651 8.3478 92.1834 9.26893 92.1834 10.64C92.1834 11.9988 93.1651 12.9118 94.6262 12.9118ZM95.0537 2.08816H94.665C93.297 2.08816 92.3777 2.93462 92.3777 4.19456C92.3777 5.4543 93.297 6.30076 94.665 6.30076H95.0537C96.4218 6.30076 97.3411 5.4543 97.3411 4.19456C97.3411 2.93462 96.4218 2.08816 95.0537 2.08816Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.57126 0.144486L0.0610746 14.5528L0 14.7108H2.51753L3.6479 11.6947H9.97523L11.1057 14.7108H13.7615L8.19078 0.144486H5.57126ZM6.81156 3.34228L9.12455 9.4617H4.49897L6.81156 3.34228Z" fill="white"/>
      <path d="M66.1679 8.82211C66.1679 11.1405 64.7989 12.6385 62.6802 12.6385C60.5188 12.6385 59.1761 11.1762 59.1761 8.82211V0.206664H56.6163V9.02885C56.6163 12.712 58.9398 15 62.6802 15C64.542 15 66.0946 14.3923 67.1703 13.2425C68.1592 12.1856 68.7038 10.6892 68.7038 9.02885V0.206664H66.1679V8.82211Z" fill="white"/>
      <path d="M79.2264 8.20336H74.155V12.4159H82.7653V14.7108H71.5696V0.206683H82.0581V2.43953H74.155V5.94976H79.2264V8.20336Z" fill="white"/>
      <path d="M19.296 11.4072L15.1997 0.283629L15.1722 0.206538H12.3904L17.9129 14.6763L17.9409 14.7522H20.5324L25.8718 0.363436L25.9291 0.206538H23.3812L19.296 11.4072Z" fill="white"/>
      <path d="M50.9992 9.83727V0.20645H53.369V14.7935H51.3954L51.3607 14.7425L44.1075 4.01051V14.7108H41.6986V0.20645H44.6341L50.9992 9.83727Z" fill="white"/>
      <path d="M30.7721 8.20336H35.8435V5.94976H30.7721V2.43953H38.675V0.206683H28.1866V14.7108H39.2852V12.4159H30.7721V8.20336Z" fill="white"/>
    </svg>
  );
}
