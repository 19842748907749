import * as React from 'react';

export function Checkmark() {
  return (
    <svg width="18px" height="12px" viewBox="0 0 18 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="MR_PropertySelected" transform="translate(-46.000000, -357.000000)" stroke="#FFFFFF">
                <g id="Group-2" transform="translate(37.000000, 345.000000)">
                    <polyline id="Path-Copy-6" transform="translate(18.000000, 15.000000) rotate(-135.000000) translate(-18.000000, -15.000000) " points="14 23 14 7 22 7"></polyline>
                </g>
            </g>
        </g>
    </svg>
  );
}