import React from 'react';
import styled from 'styled-components';
import { GoogleIcon } from './Icons/GoogleIcon';

export interface GoogleLoginButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  buttonText: string;
};

const LoginButton = styled.a`
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  padding: 0px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  outline: none !important;
  cursor: pointer;
  text-decoration: none !important;

  & > span {
    padding: 10px 10px 10px 0px;
    font-weight: 500;
}

  & > div {
    margin-right: 10px;
    background: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 2px;
  }
`;

export const GoogleLoginButton: React.FC<GoogleLoginButtonProps>  = ({ buttonText, ...props}) => {
  return (
    <LoginButton {...props}>
      <div>
        <GoogleIcon />
      </div>
      <span>{ buttonText }</span>
    </LoginButton>
  );
};
