import React from 'react';
import styled from 'styled-components';
import { LogoWhite } from './../Icons/LogoWhite';
import { theme } from '../../theme';
import { Typography } from './../Typography/Typography';

export interface DesktopHeaderProps {
  logo?: React.ReactNode;
  logoAnchorProps?: React.HTMLProps<HTMLAnchorElement>;
  rightMenu?: React.ReactNode;
}

const HeaderStyle = styled.header`
  background-color: ${theme.primaryColor};
`;

const TopContainer = styled.div`
  margin: 0 auto;
  max-width: 1248px;
  padding: 0 2rem;
  min-height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

const LogoAnchor = styled.a`
  display: inherit;
  text-decoration: none;
`;

export const DesktopHeaderRightMenuItem = (menuProps: React.HTMLAttributes<HTMLDivElement> | React.HTMLAttributes<HTMLAnchorElement>) => {
  const isAnchor = (menuProps as any).href != null;

  return (<Typography
    as={(isAnchor ? 'a' : 'div') as any}
    variant='Body'
    {...menuProps}
  >
  </Typography>)
}

const defaultLogo = (<LogoWhite width={120} />);

export const DesktopHeader: React.FC<DesktopHeaderProps> = (
  props: DesktopHeaderProps
) => {
  const { logo: logoProp, rightMenu, logoAnchorProps } = props

  let logo = logoProp || defaultLogo;
  if (logoAnchorProps) {
    logo = (<LogoAnchor {...logoAnchorProps as any}>{logo}</LogoAnchor>);
  }

  return (
    <HeaderStyle>
      <TopContainer>
        {logo}
        {rightMenu}
      </TopContainer>

    </HeaderStyle>
  )
}



