import styled from 'styled-components';

export const DesktopHeaderMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  & > *:not(*:first-child) {
    margin-left: 25px;
    @media screen and (max-width: 481px) {
      margin-left: 15px;
      padding: 2rem!important;
    }
  }
`;