import * as React from 'react';
import styled from 'styled-components';

export interface ButtonLineProps extends React.HTMLAttributes<HTMLDivElement> {
  spacing?: string;
  spacingY?: string;
  spacingX?: string;
  fullWidth?: boolean;
}

export const ButtonLineFilter = React.forwardRef((props: ButtonLineProps, ref: any) => {
  const { spacing, spacingX, spacingY, fullWidth, ...rest } = props;
  return (<div ref={ref} {...rest} />);
});

export const ButtonLineSplitter = styled.div`
  margin-left: auto!important;
  margin-right: auto!important;
  max-width: 0px;
`;

export const ButtonLine = styled(ButtonLineFilter)`
  display: ${p => p.fullWidth === true ? 'flex' : 'inline-flex'};
  align-items: center;
  flex-wrap: wrap;
  ${p => {
    const spacing = p.spacing != null ? p.spacing : "0.5rem";
    const spacingX = p.spacingX != null ? p.spacingX : spacing;
    const spacingY = p.spacingY != null ? p.spacingY : spacing;
    return `
  margin-left: -${spacingX};
  margin-right: -${spacingX};
  margin-top: -${spacingY};
  margin-bottom: -${spacingY};
  & > * {
    margin: ${spacingY} ${spacingX};
  }
  `}}
`;