import * as React from 'react';


import { Checkbox, CheckboxProps } from './Checkbox';

export type RadioProps = CheckboxProps;

export const Radio = React.forwardRef((props: RadioProps, ref: any) => {
  const { type = "radio", ...rest } = props;
  return <Checkbox ref={ref} type={type} {...rest} />
});