import { useEffect, useRef } from 'react'

const useIsMounted = () => {
  const isMountedRef = useRef(false);
  useEffect(() => {
    if(isMountedRef) isMountedRef.current = true;
    return () => {
      isMountedRef.current = false
    }
  }, []);
  return isMountedRef?.current;
};

export default useIsMounted;