import * as React from 'react'
import { ButtonGroup, InputBaseComponentProps, TextFieldProps } from '@material-ui/core'
import styled from 'styled-components';
import { theme } from '../theme';
import { A8Button } from "./Button";
import { Input } from './Input';

export type InputNumberProps = TextFieldProps & { 
  inputProps?: InputBaseComponentProps;
  incrementValue?: number;
  toFixedValue?: number;
  onIncrement?: (e: HTMLInputElement) => void;
}

export const TextFieldContainer = styled(Input)`
  .MuiOutlinedInput-adornedEnd {
    padding-right: 2px;
  }
  input {
    border-right: solid 1px ${theme.primaryColor};
  }
`;

export const InputNumber = React.forwardRef(
  (props: InputNumberProps, ref: any) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const { inputProps, incrementValue = 1, toFixedValue = 2, onIncrement, ...rest } = props;
    const stepValue = (value: number) => {
      const sum = inputRef.current ? (Number(inputRef.current.value) + value) : 0 + value;
      const MAX = Number(inputProps?.max ? inputProps?.max : (inputProps?.max != 0 ? Number.MAX_SAFE_INTEGER : 0));
      const MIN = Number(inputProps?.min ? inputProps?.min : (inputProps?.min != 0 ? Number.MIN_SAFE_INTEGER : 0));
      if(inputRef.current) {
        if(sum < MIN) {
          inputRef.current.value = (MIN).toFixed(toFixedValue);
        } else if(sum > MAX) {
          inputRef.current.value = (MAX).toFixed(toFixedValue);
        } else {
          inputRef.current.value = (sum).toFixed(toFixedValue);
        }
      }
      inputRef.current?.dispatchEvent(new Event('input', { bubbles: true }));
      inputRef.current?.focus();
      if(inputRef.current)
        onIncrement?.(inputRef.current);
    }
    
    return (
      <TextFieldContainer 
        ref={ref} 
        {...rest}
        type="number"
        InputProps={{
          inputProps: { ...inputProps, ref: inputRef },
          endAdornment: 
            <ButtonGroup disableElevation>
              <A8Button variant="text" onClick={()=>stepValue(incrementValue)}>+</A8Button>
              <A8Button variant="text" onClick={()=>stepValue(-incrementValue)}>-</A8Button>
            </ButtonGroup>
        }}
      />
    );
  }
)