export const BookmaniaFontFamily = "Bookmania, serif";
export const StyreneAFontFamily = "StyreneA, sans-serif";

export interface BaseTheme{
  fontFamily: string;
  headerFontFamily: string;
  primaryColor: string;
  disabledColor: string;
  darkGray: string;
  gray: string;
  errorColor: string;
}

export const theme: BaseTheme = {
  fontFamily: StyreneAFontFamily,
  headerFontFamily: BookmaniaFontFamily,
  primaryColor: "#2a29a6",
  disabledColor: "#b4b4df",
  darkGray: "#2A2A2A",
  gray: "#747474",
  errorColor: "#f44336",
}