import * as React from 'react';
import { Typography } from "./Typography/Typography";

export interface CompositeTitleProps extends React.HTMLAttributes<HTMLElement> {
  primaryText: string;
  secondaryText?: string;
  as?: any;
}

const normalTextMatcher = /^[*]([^*]+)[*]([^*]+)$/

function addLineBreaks(txt: string): React.ReactNode{
  if(!txt) return txt;
  return txt.split('\n').flatMap((x, i) => [i ? <br />: null, x]);
}

export const CompositeTitle = ({ primaryText, secondaryText, children, ...rest }: CompositeTitleProps) => {
  let strongText: string = "";
  let normalText: string = "";
  if (!secondaryText) {
    const match = normalTextMatcher.exec(primaryText);
    if (match != null) {
      strongText = match[1];
      if (match[2] != null) {
        normalText = match[2];
      }
    }
    else {
      strongText = primaryText;
    }
  }
  else {
    strongText = primaryText;
    normalText = secondaryText;
  }

  return (<Typography
    variant="Title"
    {...rest}
  >
    {addLineBreaks(strongText)}
    {normalText && (
      <Typography
        variant="Title"
        as={"span" as any}
        fontWeight="normal"
        fontStyle="italic"
      >
        {addLineBreaks(normalText)}
      </Typography>
    )}
  </Typography>);
}