import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  makeStyles,
  Button
} from '@material-ui/core';
import { A8Button } from './Button';
import { Typography } from './Typography/Typography';

const useStyles = makeStyles({
  modalPaper: {
    padding: '35px 0 20px 0',
    width: '340px',
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: 50,
    color: 'inherit',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  dialogActions: {
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '10px 48px'
  },
  button: {
    height: '45px',
    '&:first-of-type': {
      marginBottom: '15px'
    }
  }
});

export interface DiveBackInModalProps {
  requestType: string,
  listingName: string | null,
  continueRequest: () => void,
  continueRequestLater: () => void
}

export const DiveBackInModal: React.FC<DiveBackInModalProps> = ({
  requestType,
  listingName,
  continueRequest,
  continueRequestLater
}) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  const close = () => {
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      classes={{
        paper: classes.modalPaper
      }}
    >
      <DialogTitle>
        <Typography variant="Subheading">Dive back in?</Typography>
        <Button
          variant="text"
          disableRipple
          classes={{ root: classes.closeButton }}
          data-meta-action="btnAutosaveContinueRequestLaterClose"
          onClick={() => {
            continueRequestLater();
            close();
          }}
        >
          <CloseIcon />
        </Button>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {
            listingName
            ? (
              <Typography variant="Caption" display="block">
                It looks like your session timed out before you finished your&nbsp;
                { requestType.toLowerCase() } request for&nbsp;
                <Typography
                  variant="Caption"
                  color="primary"
                  fontWeight="bold"
                >
                  { listingName }
                </Typography>.
              </Typography>
            )
            : (
              <Typography variant="Caption" display="block">
                It looks like your session timed out before you finished your&nbsp;
                { requestType.toLowerCase() } request.
              </Typography>
            )
          }
          <Typography
            variant="Caption"
            display="block"
            padding="large"
          >
            Want to pick up where you left off?
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions disableSpacing classes={{ root: classes.dialogActions }}>
        <A8Button
          variant="contained"
          size="small"
          classes={{ root: classes.button }}
          onClick={() => {
            continueRequest();
            close();
          }}
          data-meta-action="btnAutosaveContinueRequest"
        >
          Yes, continue to request.
        </A8Button>

        <A8Button
          variant="outlined"
          size="small"
          classes={{ root: classes.button }}
          onClick={() => {
            continueRequestLater();
            close();
          }}
          data-meta-action="btnAutosaveContinueRequestLater"
        >
          Not now
        </A8Button>
      </DialogActions>
    </Dialog>
  );
};
