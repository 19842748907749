import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';


export interface DesktopScreenTitleProps {
  title: string;
  leftAction?: React.ReactNode;
  rightAction?: React.ReactNode;
  standAlone?: boolean;
}

export const Wrapper = styled.div`
  background: ${theme.primaryColor};
  margin-top:-3rem;
`;

export const Title = styled.div`
  color: white;
  letter-spacing: 0px;
  text-align: center;
  font-weight: bold;
  font-family: ${theme.headerFontFamily};
  font-size: 15px;
`;

const TitleContainer = styled.div`
  margin: 0 auto;
  max-width: 600px;
  padding: 0 2rem;
  position: relative;
  height: 3.2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 1rem;
`;

const ActionsLeft = styled.div`
  width: 200px;
  margin-right: auto;
`;
const ActionsRight = styled.div`
  width: 200px;
  margin-left: auto;
  text-align: right;
`;

export const DesktopScreenTitle = ({ title, leftAction, rightAction, standAlone }: DesktopScreenTitleProps) => {

  const addActions = leftAction != null || rightAction != null;

  return (
    <Wrapper>
      <TitleContainer style={standAlone ? { marginTop: 0 } : undefined}>
        {addActions && <ActionsLeft>{leftAction}</ActionsLeft>}
        <Title>{title}</Title>
        {addActions && <ActionsRight>{rightAction}</ActionsRight>}
      </TitleContainer>
    </Wrapper>
  );
}