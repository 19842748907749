import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../theme';

export interface A8StepperProps extends React.HTMLAttributes<HTMLDivElement> {
  activeStep: number;
  stepperSize: number;
  onStepClick?: (i: number) => void;
}

interface Step extends React.HTMLAttributes<HTMLDivElement> {
  isActive?: boolean;
}

const StepFilter = React.forwardRef((props: Step, ref: any) => {
  const { isActive, ...rest } = props;
  return (<div ref={ref} {...rest} />);
});

const Step = styled(StepFilter)`
    width: 30px;
    height: 30px;
    border: ${p => p.isActive ? `1px solid ${theme.primaryColor}ff` : `1px solid ${theme.primaryColor}00`};
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    font-size: 12px;
    font-family: ${theme.fontFamily};
    font-weight: ${p => p.isActive ? '500' : '400'};
    letter-spacing: 0.25px;
    color: ${p => p.isActive ? `${theme.primaryColor}ff` : `${theme.primaryColor}44`};
    cursor: ${p => p.onClick ? 'pointer' : 'default'};
    ${p => p.onClick ? `&:hover{
      background: ${theme.primaryColor}11;
    }`:``}
    transition: all ease-in-out 0.2s;
`;

const Divider = styled.div`
    flex: 1 1 auto;
    border-top-style: solid;
    border-top-width: 2px;
    border-color: ${theme.primaryColor}44;
`;

const StepperContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`


export const Stepper = React.forwardRef((props: A8StepperProps, ref: any) => {
  const { activeStep, stepperSize, children, ...rest } = props;

  const items: any[] = [];

  const handleClick = props.onStepClick ? (e: any)=>{
    const index = parseInt(e.target.dataset.index);
    props.onStepClick?.(index);
  } : undefined;

  for (let i = 0; i < props.stepperSize; i++) {
    items.push((<Step onClick={handleClick} data-index={i} key={`step-${i}`} isActive={i === activeStep}>{i + 1}</Step>));
    if ((i + 1) !== stepperSize) {
      items.push(<Divider key={`divider-${i}`} />);
    }
  };

  return (
    <StepperContainer ref={ref} {...rest}>
      {items}
    </StepperContainer>
  )
})