import { ButtonBase } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import { Checkmark } from './Icons/Checkmark';
import { theme } from '../theme';

export interface CheckboxProps extends React.InputHTMLAttributes<Omit<HTMLInputElement, "type">> {
  labelProps?: Omit<React.LabelHTMLAttributes<HTMLLabelElement>, "ref">,
  label?: string;
  type?: "radio" | "checkbox";
}

export const Checkbox = React.forwardRef((props: CheckboxProps, ref: any) => {
  const { label, labelProps, type = "checkbox", ...rest } = props;
  const labelRef = React.useRef(ref);

  return (<CheckboxLabel {...labelProps} ref={labelRef} data-type={type} data-disabled={props.disabled===true?"true":undefined}>
    <input ref={ref} type={type} {...rest} />
    <ButtonBase className="av8uirb-circle" disableRipple={props.disabled} onClick={props.disabled ? undefined : (e) => {
      e.preventDefault(); e.stopPropagation(); labelRef.current?.click();
    }} data-meta-id={label}>
      <Checkmark />
    </ButtonBase>
    {label}
  </CheckboxLabel>);
});

const CheckboxLabel = styled.label`
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  -webkit-user-select: none;
  align-items: center;
  color: ${p => p["data-disabled"] ? `${theme.disabledColor}` : `${theme.primaryColor}`};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: ${() => theme.fontFamily};
  font-size: 14px;
  font-weight: 500;
  justify-content: start;
  letter-spacing: 0.25px;
  margin-bottom: 16px;
  position: relative;
  user-select: none;
  hyphens: auto;
  
  /* Hide the browser's default radio button */
  & > input {
    width: 1px;
    height: 1px;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  }
  & > .av8uirb-circle {
    margin-right: 15px;
    min-height: 36px;
    min-width: 36px;
    background-color: #fff;
    border: ${p => p["data-disabled"] ? `1px solid ${theme.disabledColor}` : `1px solid ${theme.primaryColor}`};
    border-radius: ${p => p["data-type"] === 'radio' ? '50%' : '4px'};
  }
  .MuiTouchRipple-root{
      color: ${theme.primaryColor}!important;
  }
  & > input:checked ~ .av8uirb-circle {
    background-color: #2a29a6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: background-color linear 0.1s;

    .MuiTouchRipple-root{
      color: white!important;
    }
  }

  & > input:not(:checked) ~ .av8uirb-circle svg{ 
    display: none;
  }
`;