import * as React from 'react';
import { ButtonLine, ButtonLineProps } from './ButtonLine';
import { A8Button, A8ButtonProps } from './Button';
import styled from 'styled-components';
import { theme } from '../theme';
import useIsMounted from '../hooks/useIsMounted';

export interface ButtonCheckboxProps {
  options: Array<{ label: string, value: string }>;
  initialValue?: any[];
  buttonLineProps?: ButtonLineProps;
  onChange?: (value?: any[]) => void;
  value?: any[];
}

const ButtonWithCheck = ({ checked, ...rest }: A8ButtonProps & { checked?: boolean }) => {
  return <A8Button variant={checked ? "contained" : "outlined"} {...rest} />
}

export const ToggleButton = styled(ButtonWithCheck)`
  text-transform: none;
  font-family: ${theme.fontFamily};
  font-weight: 500;
  min-width: 2.6rem;
  border: solid 1px ${theme.primaryColor};
  padding: 5px 15px;
`;

export const ButtonCheckbox = ({
  initialValue,
  options,
  onChange,
  buttonLineProps,
  value: propsValue
}: ButtonCheckboxProps) => {
  const [value, setValue] = React.useState(initialValue);
  const isMounted = useIsMounted();
  const focusIndex = React.useRef<number | null>(null);
  const buttonLineRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (propsValue != null && !options.find(x => propsValue?.includes(x.value))) {
      const { current } = buttonLineRef;
      if (current && isMounted) {
        setValue(propsValue)
      }
    }
  }, [propsValue]);

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const move = (mod: number) => {
      e.preventDefault();
      e.stopPropagation();
      const targetIndex = Math.min(Math.max((focusIndex.current != null ? focusIndex.current : -1) + mod, 0), options.length);
      const div = buttonLineRef.current;
      if (div != null) {
        const element = div.querySelectorAll('button, input')[targetIndex];
        if (element) (element as any).focus();
      }
    }
    const target: any = e.target;
    if (target?.tagName === 'INPUT') { return; }
    if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
      move(-1);
    }
    else if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
      move(+1);
    }
  }

  const handleClick = (item : { label: string, value: string }) => {
    let val = [];
    if(value?.includes(item.value)) {
      val = value?.filter(v => v !== item.value);
    } else {
      val = value ? [...value, item.value] : [item.value];
    }
    setValue(val); 
    onChange?.(val);
  }

  return (<ButtonLine spacing="0.25rem" {...buttonLineProps} ref={buttonLineRef} onKeyUp={handleKeyUp}>
    {options.map((item, i) => {
      return (
        <ToggleButton 
          onFocus={() => {
            focusIndex.current = i;
          }} 
          checked={value?.includes(item.value)} 
          tabIndex={i > 0 ? -1 : undefined} 
          key={item.value} 
          onClick={() => handleClick(item)}
          data-meta-id={item.value}
        >
          {item.label}
        </ToggleButton>
      )
    })}
  </ButtonLine>);
}
