import { Button, ButtonProps, IconButton, InputAdornment, TextField, TextFieldProps, Tooltip } from "@material-ui/core";
import styled from "styled-components";
import { Search, InfoOutlined, Close } from "@material-ui/icons";
import React from 'react';

export type A8SearchBarProps = TextFieldProps & {
  withInfoIcon?: boolean;
  infoIconText?: string;
  searchButtonProps?: ButtonProps;
  clearable?: boolean;
  onClearSearchBar?: () => any;
}

const A8SearchBar = styled(TextField)`
  .MuiOutlinedInput-adornedEnd{
    padding: 0;
  }
  .MuiButton-containedPrimary {
    padding: 10px;
    border-radius: 0 4px 4px 0;
  }
  .MuiButton-root {
    min-width: 40px;
  }
  .MuiButton-startIcon{
    margin: 0;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
  }

  & fieldset,
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(p) => p.theme.primaryColor};
  }
`;

const ClearButtonContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 100%;
  top: 0;
  right: 48px;
  opacity: 0;
`;

const SearchBarContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    [aria-label='clear-search-bar'] {
      transition: 200ms;
      opacity: 1;
    }
  }
`;

export const SearchBar = ({ withInfoIcon, infoIconText, searchButtonProps, placeholder, clearable, onClearSearchBar, ...props }: A8SearchBarProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleClearSearchBar = () => {
    if(inputRef.current) {
      inputRef.current.value = "";
    }
    onClearSearchBar?.();
  }

  return (<SearchBarContainer><A8SearchBar
    variant="outlined"
    size="small"
    placeholder={placeholder ? placeholder : "Start your search"}
    inputRef={inputRef}
    {...props}
    margin="none"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <Button
            color={'primary'}
            variant="contained"
            startIcon={<Search />}
            type="submit"
            {...searchButtonProps}
          />
        </InputAdornment>),
      startAdornment: withInfoIcon
      ? (
        <InputAdornment position="start">
          <Tooltip title={ infoIconText ? infoIconText : "Search by neighborhood, zip code, address, MLS ID, city, or county"} placement="top" arrow>
            <InfoOutlined style={{marginLeft: '6px'}} color="primary"/>
          </Tooltip>
        </InputAdornment>) : null,
    }} />
      {clearable && (
        <ClearButtonContainer aria-label="clear-search-bar">
          <IconButton size="small" type="button" onClick={handleClearSearchBar}><Close/></IconButton>
        </ClearButtonContainer>
      )}
    </SearchBarContainer>
  );
}